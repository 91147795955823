import axiosInstance from "./axiosInstance";

// Fetch menus from the backend
export const createActivities = async (data) => {
  try {
    const response = await axiosInstance.post("/activities", data);
    return response.data;
  } catch (error) {
    console.error("Error creating activities:", error);
    throw error;
  }
};

export const getAnalytics = async (params) => {
  try {
    const config = {
      params,
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
        Pragma: "no-cache",
      },
    };

    const response = await axiosInstance.get(`/activities`, config);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch analytics:`, error.message);
    throw error;
  }
};
