import axiosInstance from "./axiosInstance";

export const getItemRestaurants = async (itemId) => {
  try {
    const config = {
      params: {
        _disableCache: Date.now(),
      },
      headers: {},
    };

    const response = await axiosInstance.get(
      `/items/restaurants/${itemId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      `Failed to fetch restaurants for item ${itemId}:`,
      error.message
    );
    throw error;
  }
};

export const copyItemToRestaurants = async (data) => {
  try {
    const response = await axiosInstance.post(
      `/items/restaurants/duplicate`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(`Error copy item with id ${data}:`, error);
    throw error;
  }
};
