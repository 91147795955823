import React, { useState } from "react";
import {
  Box,
  TextField,
  ClickAwayListener,
  InputAdornment,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TimePicker } from "@mui/x-date-pickers/TimePicker"; // Use MUI X TimePicker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // Using AdapterDayjs instead of AdapterDateFns
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

function TimeRangePicker() {
  const [timeRange, setTimeRange] = useState({
    startTime: null,
    endTime: null,
  });
  const [showTimePicker, setShowTimePicker] = useState(false);

  const formattedStartTime = timeRange.startTime
    ? timeRange.startTime.format("hh:mm A")
    : "";
  const formattedEndTime = timeRange.endTime
    ? timeRange.endTime.format("hh:mm A")
    : "";

  const handleClickAway = () => {
    setShowTimePicker(false);
  };

  const handleTextFieldClick = () => {
    setShowTimePicker((prev) => !prev);
  };

  const handleTimeChange = (time, key) => {
    setTimeRange((prev) => ({ ...prev, [key]: time }));
  };

  return (
    <Box sx={{ position: "absolute" }}>
      <TextField
        variant="outlined"
        size="small"
        value={
          formattedStartTime && formattedEndTime
            ? `${formattedStartTime} - ${formattedEndTime}`
            : "hh:mm AM/PM - hh:mm AM/PM"
        }
        onClick={handleTextFieldClick}
        readOnly
        fullWidth
        sx={{
          backgroundColor: "#f5f5f5",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccessTimeIcon />
            </InputAdornment>
          ),
        }}
      />
      {showTimePicker && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 100,
              left: 0,
              mt: 1,
              bottom: 0,
              boxShadow: 3,
              backgroundColor: "background.paper",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Start Time"
                  value={timeRange.startTime}
                  onChange={(time) => handleTimeChange(time, "startTime")}
                  renderInput={(params) => <TextField {...params} />}
                  ampm
                  views={["hours", "minutes"]}
                />
              </DemoContainer>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="End Time"
                  value={timeRange.endTime}
                  onChange={(time) => handleTimeChange(time, "endTime")}
                  renderInput={(params) => <TextField {...params} />}
                  ampm
                  views={["hours", "minutes"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default TimeRangePicker;
