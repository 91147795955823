import React, { useContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import HeaderSection from "../../components/HeaderSection";
import { MenuContext } from "../../contexts/MenuContext";
import MenuListUser from "./MenuListUser";
import ItemListUser from "./ItemListUser";
import CategoryListUser from "./CategoryListUser";
import { useTheme } from "@mui/material/styles";
import { isImageUrl } from "../../utils/isImageUrl";
import { useAuth } from "../../contexts/AuthContext";
import ItemDetails from "../../components/itemDetails";
import { useActivity } from "../../contexts/ActivityContext";
import { getItemById } from "../../services/itemService";
import { useLoading } from "../../contexts/LoadingContext";

const dimensions = {
  contentContainerWidth: 992,
  itemListBoxPadding: 16,
  upperContentHeight: 68,
};

const calculateDime = (screenSize = "md") => {
  const maxWidth = dimensions.contentContainerWidth + "px";
  let height = "";
  if (screenSize === "md") {
    height = `${dimensions.upperContentHeight}svh`;
  } else if (screenSize === "xs") {
    height = `55svh`;
  }
  return { maxWidth, height };
};

const MenuPage = () => {
  const theme = useTheme();
  const {
    menus,
    filteredItems,
    categories,
    activeMenu,
    selectedCategory,
    handleMenuClick,
    handleCategoryClick,
  } = useContext(MenuContext);
  const { themeBg } = useAuth();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const { trackItem } = useActivity();
  const [contentHeight, setContentHeight] = useState(0); // Start with height 0 for initial grow effect

  useEffect(() => {
    // Increase height gradually after mount
    setTimeout(() => {
      setContentHeight({
        xs: showOverlay ? 0 : calculateDime("xs").height,
        md: showOverlay ? 0 : calculateDime("md").height,
      });
    }, 300); // Adjust delay for smooth initial growth
  }, [showOverlay]);

  const handleOpenOverlay = async (item) => {
    // startLoading();
    try {
      const { data } = await getItemById(item.id);
      trackItem(item.id);
      setSelectedItem(data);
      setShowOverlay(true);
    } catch (error) {
      console.error(error);
    }
    // finally {
    //   stopLoading();
    // }
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setTimeout(() => {
      setSelectedItem(null);
    }, 1000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100svh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: isImageUrl(themeBg) ? `url(${themeBg})` : "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "fixed",
          top: 0,
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          height: contentHeight, // Use dynamic height
          width: "100vw",
          alignItems: "center",
          p: 0,
          m: 0,
          px: { xs: "16px", md: 0 },
          transition: "height 0.5s ease", // Smooth transition for initial and overlay-triggered changes
          overflow: "hidden",
        }}
      >
        <Box sx={{ maxWidth: { md: calculateDime("md").maxWidth } }}>
          <HeaderSection />
          <MenuListUser
            menus={menus}
            activeMenu={activeMenu}
            handleMenuClick={handleMenuClick}
          />
          <CategoryListUser
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryClick={handleCategoryClick}
          />
        </Box>
      </Box>
      <ItemDetails
        height={dimensions.upperContentHeight}
        selectedItem={selectedItem}
        handleCloseOverlay={handleCloseOverlay}
      />
      <ItemListUser
        items={filteredItems}
        selectedCategory={selectedCategory}
        dimensions={dimensions}
        handleOpenOverlay={handleOpenOverlay}
        handleCloseOverlay={handleCloseOverlay}
      />
    </Box>
  );
};

export default MenuPage;
