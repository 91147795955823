import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";

const StatisticsBar = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: 0.5,
            overflow: "hidden",
            height: "26px",
            width: "100%",
          }}
        >
          {/* Filled Bar */}
          <Box
            sx={{
              height: "100%",
              width: `${item.percentage}%`,
              backgroundColor: item.color,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              color: "#fff",
              fontSize: "0.75rem",
            }}
          ></Box>

          <Box
            sx={{
              flexGrow: 1,
              position: "absolute",
              display: "flex",
              right: 3,
              width: "50%",
              gap: 0.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                whiteSpace: "nowrap",
                borderRadius: 0.5,
                fontWeight: 500,
                bgcolor: "white",
                py: "2px",
                px: "5px",
                width: "30%",
                fontSize: 12,
              }}
            >
              {item.percentage}% ({item.value})
            </Typography>

            {/* Label with Tooltip */}
            <Tooltip title={<Typography>{item.label}</Typography>} arrow>
              <Typography
                variant="body2"
                sx={{
                  color: "#333",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  borderRadius: 0.5,
                  fontWeight: 500,
                  fontSize: 12,

                  bgcolor: "white",
                  py: "2px",
                  px: "5px",
                  width: "60%",
                }}
              >
                {item.label}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default StatisticsBar;
