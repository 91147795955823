import React from "react";
import { Box, Typography } from "@mui/material";
import StatisticsBar from "./StatisticsBar"; // Import your StatisticsBar component

const StatisticsChart = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        border: "1px solid #ccc",
        p: 1,
        borderRadius: "10px",
      }}
    >
      {data.map((section, sectionIndex) => (
        <Box
          key={sectionIndex}
          sx={{
            flex: 1,
            borderRight:
              sectionIndex < data.length - 1 ? "1px solid #ccc" : "none", // Adds border except for the last item
            pr: 2, // Optional: add padding to the right side for spacing
          }}
        >
          {/* Section labels for min and max values */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography variant="caption" fontSize={14}>
              {section.minLabel}
            </Typography>
            <Typography variant="caption" fontSize={14}>
              {section.midLabel}
            </Typography>
            <Typography variant="caption" fontSize={14}>
              {section.maxLabel}
            </Typography>
          </Box>

          {/* Render StatisticsBar for each section */}
          <StatisticsBar data={section.items} />
        </Box>
      ))}
    </Box>
  );
};

export default StatisticsChart;
