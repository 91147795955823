import useCrud from "../hooks/useCrud";

const useCrudFactory = (endpoint) => {
  const {
    createData,
    updateData,
    deleteData,
    getDataById,
    setActivationState,
    loading,
    error,
    fieldErrors,
    data,
    setData,
    fetchData,
  } = useCrud(endpoint);

  return {
    createData,
    updateData,
    deleteData,
    getDataById,
    setActivationState,
    loading,
    error,
    fieldErrors,
    data,
    setData,
    fetchData,
  };
};

export default useCrudFactory;
