import { useState } from "react";
import apiService from "../services/apiService";
import { useSnackbar } from "notistack";

const useCrud = (endpoint) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const showNotification = (response, fallbackMessage, variant) => {
    const message = response?.data?.message || fallbackMessage;
    enqueueSnackbar(message, { variant });
  };

  const parseFieldErrors = (errorDetails) => {
    const errors = {};
    if (errorDetails && Array.isArray(errorDetails)) {
      errorDetails.forEach((detail) => {
        const fieldName = detail.split('"')[1];
        errors[fieldName] = detail;
      });
    }
    setFieldErrors(errors);
  };

  const clearErrors = () => {
    setError(null);
    setFieldErrors({});
  };

  const fetchData = async () => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.get(endpoint);
      setData(response.data.data);
      return response.data.data;
    } catch (err) {
      setError(err);
      showNotification(err.response, "Failed to fetch data.", "error");
    } finally {
      setLoading(false);
    }
  };

  const createData = async (newData) => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.post(endpoint, newData);
      setData([...data, response.data]);
      showNotification(response, "Data created successfully!", "success");
      return response.data;
    } catch (err) {
      setError(err);
      showNotification(err.response, "Failed to create data.", "error");
      parseFieldErrors(err.response?.data?.error?.details);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateData = async (id, updatedData) => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.put(`${endpoint}/${id}`, updatedData);
      setData(data.map((item) => (item.id === id ? response.data : item)));
      showNotification(response, "Data updated successfully!", "success");
      return response.data;
    } catch (err) {
      setError(err);
      showNotification(err.response, "Failed to update data.", "error");
      parseFieldErrors(err.response?.data?.error?.details);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (id) => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.delete(`${endpoint}/${id}`);
      setData(data.filter((item) => item.id !== id));
      showNotification(response, "Data deleted successfully!", "success");
      return response.data;
    } catch (err) {
      setError(err);
      showNotification(err.response, "Failed to delete data.", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const getDataById = async (id) => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.get(`${endpoint}/${id}`);
      return response.data.data;
    } catch (err) {
      setError(err);
      showNotification(err.response, "Failed to fetch data.", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const setActivationState = async (id, isActive) => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.patch(`${endpoint}/${id}`, {
        active: isActive,
      });
      setData(
        data.map((item) =>
          item.id === id ? { ...item, active: isActive } : item
        )
      );
      showNotification(
        response,
        `Item ${isActive ? "activated" : "deactivated"} successfully!`,
        "success"
      );
      return response.data;
    } catch (err) {
      setError(err);
      showNotification(
        err.response,
        "Failed to update activation state.",
        "error"
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const reorderData = async (reorderedItems) => {
    clearErrors();
    setLoading(true);
    try {
      const response = await apiService.post(
        `${endpoint}/reorder`,
        reorderedItems
      );
      showNotification(response, "Items reordered successfully!", "success");
      return response.data;
    } catch (err) {
      setError(err);
      showNotification(err.response, "Failed to reorder items.", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    setData, // expose setData to allow external updates directly
    loading,
    error,
    fieldErrors,
    createData,
    updateData,
    deleteData,
    fetchData,
    getDataById,
    setActivationState,
    reorderData,
  };
};

export default useCrud;
