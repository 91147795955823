import React, { createContext, useContext, useEffect, useState } from "react";
import { getAnalytics } from "../services/activityService";
import { useSnackbar } from "notistack";

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const [analytics, setAnalytics] = useState([]);
  const [params, setParams] = useState({
    activityType: "items",
    startDate: new Date(
      new Date().setDate(new Date().getDate() - 7)
    ).toISOString(),
    endDate: new Date().toISOString(),
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchAnalytics = async (params) => {
      setLoading(true);
      try {
        const { data } = await getAnalytics(params);
        setAnalytics(data);
      } catch (error) {
        enqueueSnackbar("Failed to fetch analytics data", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (
      params.startDate &&
      params.endDate &&
      params.startDate !== params.endDate
    )
      fetchAnalytics(params);
  }, [params]);

  return (
    <AnalyticsContext.Provider
      value={{ analytics, loading, setParams, params }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
