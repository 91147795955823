import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import * as XLSX from "xlsx";

const TableData = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Calculate the paginated data
  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + rowsPerPage);

  // Export Data to Excel
  const handleExport = () => {
    const exportData = data.map((item, index) => ({
      No: index + 1,
      Title: item.name || "Unnamed Item",
      Status: item.active ? "Active" : "Inactive",
      Clicks: item.total_clicks,
      "Time Spent (sec)": item.total_time_spent,
      "Number of Orders": item.total_orders,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  return (
    <>
      <Box>
        {/* Table */}
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 300, // Set a maximum height for scrollable behavior
            overflow: "auto", // Enable scrolling when content overflows
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Clicks</TableCell>
                <TableCell>Time Spent</TableCell>
                <TableCell>Number of Orders</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item, index) => (
                <TableRow
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: 40, // Set row height
                  }}
                >
                  <TableCell sx={{ py: 0.5 }}>
                    {index + 1 + startIndex}
                  </TableCell>
                  <TableCell sx={{ py: 0.5 }}>
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={item.image}
                        alt={item.name || "Unnamed"}
                        sx={{
                          width: 40,
                          height: 40,
                          marginRight: 2,
                          backgroundColor: "grey.300",
                          fontSize: 14,
                        }}
                      >
                        {item.name ? item.name.charAt(0).toUpperCase() : "?"}
                      </Avatar>
                      {item.name || "Unnamed Item"}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 0.5 }}>
                    <Box
                      sx={{
                        display: "inline-block",
                        px: 2,
                        py: 0.5,
                        borderRadius: 2,
                        backgroundColor: item.active
                          ? "rgba(76, 175, 80, 0.1)"
                          : "rgba(244, 67, 54, 0.1)",
                        color: item.active ? "green" : "red",
                      }}
                    >
                      {item.active ? "Active" : "Inactive"}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 0.5 }}>{item.total_clicks}</TableCell>
                  <TableCell sx={{ py: 0.5 }}>
                    {item.total_time_spent} sec
                  </TableCell>
                  <TableCell sx={{ py: 0.5 }}>{item.total_orders}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Table Footer */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: "0 0 4px 4px",
          borderTop: "1px solid #E0E0E0",
        }}
      >
        {/* Rows Per Page Selector */}
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <Select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value));
              setCurrentPage(1); // Reset to the first page when rows per page changes
            }}
          >
            <MenuItem value={5}>5 per page</MenuItem>
            <MenuItem value={10}>10 per page</MenuItem>
            <MenuItem value={25}>25 per page</MenuItem>
          </Select>
        </FormControl>

        {/* Pagination */}
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          variant="outlined"
          color="primary"
        />

        {/* Export Button */}
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={handleExport}
        >
          Export
        </Button>
      </Box>
    </>
  );
};

export default TableData;
