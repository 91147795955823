import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

const TableToolbar = ({
  title = "Items",
  activeCount = 0,
  searchPlaceholder = "Search...",
  dropdownOptions = ["id", "name", "active", "clicks", "time_spent", "orders"],
  onSearchChange,
  onDropdownChange,
}) => {
  const [dropdownValue, setDropdownValue] = useState("");

  const handleSearchChange = (e) => {
    if (onSearchChange) onSearchChange(e.target.value);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setDropdownValue(selectedValue);
    if (onDropdownChange) onDropdownChange(selectedValue);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: 2 }}>
      {/* Title with Active Count */}
      <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Typography variant="h6" sx={{ mr: 1 }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: "#f0f0f0",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {activeCount}
        </Typography>
      </Box>

      {/* Search Input */}
      <TextField
        variant="outlined"
        size="small"
        placeholder={searchPlaceholder}
        onChange={handleSearchChange}
        sx={{
          flexGrow: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#e0e0e0",
            },
          },
        }}
      />

      {/* Sort Dropdown */}
      <FormControl
        size="small"
        sx={{
          minWidth: 100,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e0e0e0",
          },
        }}
      >
        <Select
          value={dropdownValue}
          onChange={handleDropdownChange}
          displayEmpty
          sx={{
            "& .MuiSelect-select": {
              padding: "8px",
            },
          }}
        >
          <MenuItem value="" disabled>
            Sort by
          </MenuItem>
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option.charAt(0).toUpperCase() +
                option.slice(1).replace("_", " ")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TableToolbar;
