import Login from "../pages/Login";
import NotFound from "../pages/Not-Found";
import Startup from "../pages/Startup";
import Menu from "../pages/Menu";
import Dashboard from "../pages/admin/dashboard";
import Analytics from "../pages/admin/analytics";

export const routes = [
  {
    path: "/",
    element: <Startup />,
    roles: ["user"],
    redirectTo: "/startup",
  },
  {
    path: "/login",
    element: <Login />,
    public: true,
    redirectTo: "/startup", // Redirect logged-in users trying to access /login
  },
  {
    path: "/startup",
    element: <Startup />,
    roles: ["user"],
    redirectTo: "/startup", // Admin/superadmin redirected to dashboard
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    roles: ["admin", "superadmin"],
    providers: ["CurrencyProvider", "CartProvider", "DashboardProvider"],
  },
  {
    path: "/analytics",
    element: <Analytics />,
    roles: ["admin", "superadmin"],
    providers: ["AnalyticsProvider"],
  },
  {
    path: "/menu",
    element: <Menu />,
    roles: ["user"],
    providers: [
      "ActivityProvider",
      "MenuProvider",
      "CurrencyProvider",
      "CartProvider",
    ],
  },
  // Catch-all route for undefined paths
  {
    path: "*",
    element: <NotFound />,
    public: true, // Public to allow access for any user
  },
];
