import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ImportFileField = forwardRef(
  ({ onFileUpload, fileName, allowedExtensions }, ref) => {
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const fileExtension = file.name.split(".").pop().toLowerCase();

      // Validate file extension
      if (!allowedExtensions.includes(fileExtension)) {
        setError(
          `Invalid file type. Please upload a file with one of the following extensions: ${allowedExtensions.join(
            ", "
          )}.`
        );
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      setSelectedFile(file);
      setIsUploading(true);
      setError(null);

      try {
        const fileUrl = URL.createObjectURL(file); // Generate URL for the file
        onFileUpload(file.name, fileUrl, file);
        setIsUploading(false);

        // Reset the input value to allow re-uploading the same file
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (err) {
        setSelectedFile(null);
        setError(err?.response?.data?.message || "Error uploading file");
        setIsUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    };

    const handleFileRemove = () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      onFileUpload(null, null);
      setSelectedFile(null);
      setError(null);
    };

    useImperativeHandle(ref, () => ({
      removeFile: handleFileRemove,
    }));

    const renderFilePreview = () => (
      <Box
        sx={{
          bgcolor: "#eee",
          paddingY: 0.5,
          paddingX: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 1,
          maxWidth: "95%",
        }}
      >
        <Typography
          sx={{
            marginRight: 1,
            fontSize: "0.875rem",
            lineHeight: "1",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {selectedFile ? selectedFile.name : fileName?.name || fileName}
        </Typography>
        <DeleteIcon
          onClick={handleFileRemove}
          size="small"
          sx={{
            p: 0.5,
            height: "20px",
            width: "20px",
            color: "#333",
            cursor: "pointer",
          }}
        />
      </Box>
    );

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "50px",
          width: "100%",
        }}
      >
        <Button
          component="label"
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: "10px 0 0 10px",
            bgcolor: "#ccc",
            border: "1px solid #999",
            m: 0,
            color: "#333",
            height: "100%",
            padding: 0,
            display: "flex",
            alignItems: "center",
            minWidth: "100px",
            borderRight: "none",
            boxShadow: "none",
            width: "35%",
          }}
          disabled={isUploading}
        >
          Choose file
          <input
            type="file"
            hidden
            onChange={handleFileChange}
            ref={fileInputRef}
            onClick={(event) => {
              event.target.value = null; // Reset value to allow re-uploading the same file
            }}
          />
        </Button>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #999",
            borderRadius: "0 10px 10px 0",
            padding: "0 8px",
            boxSizing: "border-box",
            height: "100%",
            width: "65%",
            m: 0,
            borderLeft: "none",
          }}
        >
          {selectedFile || fileName ? (
            renderFilePreview()
          ) : error ? (
            <Typography
              sx={{
                color: "red",
                fontSize: "0.875rem",
                lineHeight: "1",
              }}
            >
              {error}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }
);

export default ImportFileField;
