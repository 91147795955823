import axiosInstance from "./axiosInstance";

// Import Data - POST request to upload a file
export const importData = async (file, onProgress) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axiosInstance.post("/transfer/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgress) {
          onProgress(progress);
        }
      },
    });
    return response.data.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

// Export Data - GET request to download a file
export const exportData = async () => {
  try {
    const response = await axiosInstance.get("/transfer/export", {
      responseType: "blob", // Important: This allows downloading binary data
      params: { _: Date.now() },
    });

    // Create a URL for the downloaded file and initiate download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_data.xlsx"); // Set file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
