import React, { createContext, useContext, useEffect, useState } from "react";
import useCrudFactory from "../hooks/useCrudFactory";
import { useLoading } from "./LoadingContext";
import { extractUniqueCategories } from "../utils/categoryUtils";
import { filterItemsByCategory as filterItemsByCategoryUtil } from "../utils/itemUtils";
import apiService from "../services/apiService";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const { startLoading, stopLoading, isLoading } = useLoading();
  const [menuItems, setMenuItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  // Using useCrudFactory for different endpoints
  const menusCrud = useCrudFactory("/menus");
  const itemsCrud = useCrudFactory("/items");
  const unusedCategoriesCrud = useCrudFactory("/categories/unused");
  const currenciesCrud = useCrudFactory("/currencies");
  const languagesCrud = useCrudFactory("/languages");
  const categoriesNamesCrud = useCrudFactory("/categories");
  const allergiesCrud = useCrudFactory("/allergies");

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        // Fetch items, unused categories, menus, and other resources concurrently
        const [unusedCategoriesResponse] = await Promise.all([
          unusedCategoriesCrud.fetchData(),
          itemsCrud.fetchData(),
          menusCrud.fetchData(),
          categoriesNamesCrud.fetchData(),
        ]);

        // itemsCrud.setData(allItems);
        if (activeMenu !== 0) {
          handleMenuClick(activeMenu);
        } else {
          setCategories(unusedCategoriesResponse);
        }
        if (unusedCategoriesResponse.length > 0) {
          setSelectedCategory(unusedCategoriesResponse[0].id);
        }
      } catch (error) {
        setError("Failed to load data");
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [reloadTrigger]);

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        const data = await Promise.all([
          currenciesCrud.fetchData(),
          languagesCrud.fetchData(),
          allergiesCrud.fetchData(),
        ]);
      } catch (error) {
        setError("Failed to load data");
      }
    };

    fetchData();
  }, []);

  const reloadData = () => setReloadTrigger((prev) => prev + 1);

  const setUniqueCategories = (items) => {
    const uniqueCategories = extractUniqueCategories(items);
    if (uniqueCategories.length > 0) {
      setCategories(uniqueCategories);
      setSelectedCategory(uniqueCategories[0]);
      filterItemsByCategory(items, uniqueCategories[0].id);
    } else {
      console.log("No unique categories found.");
    }
  };

  const filterItemsByCategory = (itemsToFilter = menuItems, categoryId) => {
    const filtered = filterItemsByCategoryUtil(itemsToFilter, categoryId);
    setFilteredItems(filtered);
  };

  const handleMenuClick = async (id) => {
    setActiveMenu(id);
    if (id === 0) {
      setCategories(unusedCategoriesCrud.data);
      // itemsCrud.setData([]); // Clear items using setData from itemsCrud
      setFilteredItems([]);
      return;
    }
    startLoading();
    try {
      const {
        data: { data: itemsByMenuId },
      } = await apiService.get(`/items/menu/${id}`);
      // itemsCrud.setData(items);
      setMenuItems(itemsByMenuId);
      setFilteredItems(itemsByMenuId);
      setUniqueCategories(itemsByMenuId);
    } catch (error) {
      setError("Failed to load items");
    } finally {
      stopLoading();
    }
  };

  const handleCategoryClick = (id) => {
    const selected = categories.find((category) => category.id === id);
    if (selected) {
      setSelectedCategory(selected);
      filterItemsByCategory(menuItems, id);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        itemsCrud,
        menusCrud,
        categoriesCrud: categoriesNamesCrud,
        items: itemsCrud.data,
        unusedCategories: unusedCategoriesCrud.data,
        menus: menusCrud.data,
        filteredItems,
        categories,
        activeMenu,
        selectedCategory,
        handleMenuClick,
        handleCategoryClick,
        error,
        isLoading,
        currencies: currenciesCrud.data,
        languages: languagesCrud.data,
        categoriesNames: categoriesNamesCrud.data,
        allergies: allergiesCrud.data,
        reloadData,
        setActivationMenu: menusCrud.setActivationState,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
