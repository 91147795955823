import axiosInstance from "./axiosInstance";

// CRUD operations
const apiService = {
  get: (url, params, disableCache = true) => {
    const config = {
      params,
      headers: {},
    };

    if (disableCache) {
      config.headers["Cache-Control"] = "no-cache";
      config.headers["Pragma"] = "no-cache";
      config.params = {
        ...params,
        _disableCache: Date.now(),
      };
    }

    return axiosInstance.get(url, config);
  },
  post: (url, data) => axiosInstance.post(url, data),
  put: (url, data) => axiosInstance.put(url, data),
  delete: (url) => axiosInstance.delete(url),
  patch: (url, data) => axiosInstance.patch(url, data),
};

export default apiService;
