import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onDelete,
  itemName,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="delete-dialog-title"
    >
      <div
        style={{ padding: "20px", textAlign: "center", position: "relative" }}
      >
        <DialogTitle>
          <CloseDialogButton onClose={onClose} loading={loading} />
        </DialogTitle>

        <DialogContent>
          <NewReleasesRoundedIcon
            style={{ fontSize: "48px", marginBottom: 10 }}
          />
          <Typography variant="h4">
            Are you sure you want to delete "{itemName}"?
          </Typography>
          <p style={{ color: "#666", marginBottom: "20px" }}>
            If you delete the item, all settings will be gone.
          </p>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            style={{
              padding: "8px 32px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onDelete}
            variant="contained"
            color="error"
            style={{
              padding: "8px 32px",
              textTransform: "none",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
