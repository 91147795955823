import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";
import {
  getItemRestaurants,
  copyItemToRestaurants,
} from "../services/copyService";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";

const CopyDialog = ({ open, onClose, item }) => {
  const { auth } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [itemRestaurantsDetails, setItemRestaurantsDetails] = useState(null);
  const [copyBody, setCopyBody] = useState({
    item_id: item?.id || null, // Use a fallback to avoid errors
    restaurantIds: [],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItemRestaurants = async () => {
      if (!open) return;
      setLoading(true);
      try {
        const { data } = await getItemRestaurants(item.id);
        setItemRestaurantsDetails(data);
        setCopyBody((prev) => ({
          item_id: item.id, // Ensure only the `item_id` is kept
          restaurantIds: data.restaurants
            .filter((restaurant) => restaurant.item_exists)
            .map((restaurant) => restaurant.id),
        }));
      } catch (error) {
        console.error("Error fetching item restaurants:", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (open && item && item.id) fetchItemRestaurants();
  }, [open]);

  const handleApplyCopy = async () => {
    setLoading(true);
    try {
      await copyItemToRestaurants(copyBody);
      enqueueSnackbar("Item successfully copied to selected restaurants.", {
        variant: "success",
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.error("Error copying item:", error.message);
      enqueueSnackbar(
        "Failed to copy item to the selected restaurants. Please try again.",
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelectRestaurant = (restaurantId) => {
    setCopyBody((prev) => {
      const updatedIds = new Set(prev.restaurantIds);
      if (updatedIds.has(restaurantId)) {
        updatedIds.delete(restaurantId); // Remove if it exists
      } else {
        updatedIds.add(restaurantId); // Add if it doesn't exist
      }
      return {
        ...prev,
        restaurantIds: Array.from(updatedIds), // Convert back to array
      };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>
        Do you want to apply changes to other restaurants?
        <CloseDialogButton onClose={onClose} loading={loading} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">Copy "{item.name}"</Typography>
        <Typography variant="h6" my={2}>
          Select restaurants
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {itemRestaurantsDetails?.restaurants.map((restaurant) => (
            <Box
              key={restaurant.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "160px",
                height: "120px",
                backgroundColor: restaurant.theme?.secondary_color || "#fff",
                backgroundImage: `url(${
                  restaurant.theme?.theme_bg || "unset"
                })`,
                border: copyBody.restaurantIds.includes(restaurant.id)
                  ? "2px solid #555"
                  : "1px solid #ccc",
                boxSizing: "border-box",
                borderRadius: 2,
                cursor:
                  auth.user.restaurant.id === restaurant.id
                    ? "not-allowed"
                    : "pointer",
                opacity:
                  auth.user.restaurant.id === restaurant.id ? 0.5 : "unset",
              }}
              onClick={() => {
                if (auth.user.restaurant.id !== restaurant.id)
                  handleSelectRestaurant(restaurant.id);
              }}
            >
              <Typography variant="h6">{restaurant.name}</Typography>
              <Divider />
              <Typography variant="body2">
                Under {itemRestaurantsDetails.brand.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          px: 2,
          mb: 2,
        }}
      >
        <Button
          onClick={handleApplyCopy}
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{ px: 5 }}
        >
          {loading ? <CircularProgress size={24} /> : "Apply"}
        </Button>
        <Button
          sx={{ px: 4, mx: 1 }}
          variant="outlined"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyDialog;
